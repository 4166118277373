<template>
  <div
    class="p-2 md:mt-0"
    :class="`${this.$store.state.eCommerce.showAlertHeader ? '' : 'mt-0'}`"
    style="margin-top: 63px"
  >
    <vs-breadcrumb
      class="block md:hidden"
      :items="breadcrumbItems"
      separator="chevron_right"
    >
    </vs-breadcrumb>
    <h1 class="pb-3">Promotions and New Arrivals</h1>
    <vs-breadcrumb
      class="hidden md:block mb-10"
      :items="breadcrumbItems"
      separator="chevron_right"
    >
    </vs-breadcrumb>

    <div class="bg-opacity-100 w-full min-h-screen">
      <div class="grid grid-cols-1 sm:grid-cols-2">
        <!-- Card 1   -->
        <div
          class="p-6 flex flex-col border-solid border"
          style="border-color: #e5e7eb"
        >
          <img
            class="w-full hidden md:block"
            :src="JsonData.card_1.desktop_image_url"
            alt="img"
          />
          <img
            class="w-full md:hidden"
            :src="JsonData.card_1.mobile_image_url"
            alt="img"
          />
          <h3 class="pt-4">{{ JsonData.card_1.title }}</h3>
          <div>
            {{ JsonData.card_1.description }}
          </div>
          <div class="w-full h-full flex justify-center items-end mt-8">
            <vs-button @click="redirectToURL(`${JsonData.card_1.link}`)" class="mb-3">
              {{ JsonData.card_1.button_text }}
            </vs-button>
          </div>
        </div>
        <!-- Card 2   -->
        <!-- Card 2   -->

        <div
          class="p-6 flex flex-col border-t-0 border sm:border-l-0 sm:border border-solid"
          style="border-color: #e5e7eb"
        >
          <img
            class="w-full hidden md:block"
            :src="JsonData.card_2.desktop_image_url"
            alt="img"
          />
          <img
            class="w-full md:hidden"
            :src="JsonData.card_2.mobile_image_url"
            alt="img"
          />
          <h3 class="pt-4">{{ JsonData.card_2.title }}</h3>
          <div>
            {{ JsonData.card_2.description }}
          </div>
          <div class="w-full h-full flex justify-center items-end mt-8">
            <vs-button @click="redirectToURL(`${JsonData.card_2.link}`)" class="mb-3">
              {{ JsonData.card_2.button_text }}
            </vs-button>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1">
        <!-- Card 3   -->

        <div
          class="p-6 flex flex-col border-solid border border-t-0 border-b-0"
          style="border-color: #e5e7eb"
        >
          <img
            class="w-full hidden md:block"
            :src="JsonData.card_3.desktop_image_url"
            alt="img"
          />
          <img
            class="w-full md:hidden"
            :src="JsonData.card_3.mobile_image_url"
            alt="img"
          />
          <h3 class="pt-4">{{ JsonData.card_3.title }}</h3>
          <div>
            {{ JsonData.card_3.description }}
          </div>
          <div class="w-full h-full flex items-end justify-center sm:justify-start mt-8">
            <vs-button @click="redirectToURL(`${JsonData.card_3.link}`)" class="mb-3">
              {{ JsonData.card_3.button_text }}
            </vs-button>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2">
        <!-- Card 4   -->

        <div
          class="p-6 flex flex-col border-solid border"
          style="border-color: #e5e7eb"
        >
          <img
            class="w-full hidden md:block"
            :src="JsonData.card_4.desktop_image_url"
            alt="img"
          />
          <img
            class="w-full md:hidden"
            :src="JsonData.card_4.mobile_image_url"
            alt="img"
          />
          <h3 class="pt-4">{{ JsonData.card_4.title }}</h3>
          <div>
            {{ JsonData.card_4.description }}
          </div>
          <div class="w-full h-full flex justify-center items-end mt-8">
            <vs-button @click="redirectToURL(`${JsonData.card_4.link}`)" class="mb-3">
              {{ JsonData.card_4.button_text }}
            </vs-button>
          </div>
        </div>
        <!-- Card 5   -->
        <!-- Card 5   -->

        <div
          class="p-6 flex flex-col border-t-0 border sm:border-l-0 sm:border border-solid"
          style="border-color: #e5e7eb"
        >
          <img
            class="w-full hidden md:block"
            :src="JsonData.card_5.desktop_image_url"
            alt="img"
          />
          <img
            class="w-full md:hidden"
            :src="JsonData.card_5.mobile_image_url"
            alt="img"
          />
          <h3 class="pt-4">{{ JsonData.card_5.title }}</h3>
          <div>
            {{ JsonData.card_5.description }}
          </div>
          <div class="w-full h-full flex justify-center items-end mt-8">
            <vs-button @click="redirectToURL(`${JsonData.card_5.link}`)" class="mb-3">
              {{ JsonData.card_5.button_text }}
            </vs-button>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1">
        <!-- Card 6   -->
        <div
          class="p-6 flex flex-col border-solid border border-t-0 border-b-0"
          style="border-color: #e5e7eb"
          v-if="JsonData.card_6"
        >
          <img
            class="w-full hidden md:block"
            :src="JsonData.card_6.desktop_image_url"
            alt="img"
          />
          <img
            class="w-full md:hidden"
            :src="JsonData.card_6.mobile_image_url"
            alt="img"
          />
          <h3 class="pt-4">{{ JsonData.card_6.title }}</h3>
          <div>
            {{ JsonData.card_6.description }}
          </div>
          <div class="w-full h-full flex items-end justify-center sm:justify-start mt-8">
            <vs-button @click="redirectToURL(`${JsonData.card_6.link}`)" class="mb-3">
              {{ JsonData.card_6.button_text }}
            </vs-button>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2">
        <!-- Card 7   -->
        <div
          class="p-6 flex flex-col border-solid border"
          style="border-color: #e5e7eb"
          v-if="JsonData.card_7"
        >
          <img
            class="w-full hidden md:block"
            :src="JsonData.card_7.desktop_image_url"
            alt="img"
          />
          <img
            class="w-full md:hidden"
            :src="JsonData.card_7.mobile_image_url"
            alt="img"
          />
          <h3 class="pt-4">{{ JsonData.card_7.title }}</h3>
          <div>
            {{ JsonData.card_7.description }}
          </div>
          <div class="w-full h-full flex justify-center items-end mt-8">
            <vs-button @click="redirectToURL(`${JsonData.card_7.link}`)" class="mb-3">
              {{ JsonData.card_7.button_text }}
            </vs-button>
          </div>
        </div>
        <!-- Card 8   -->

        <div
          class="p-6 flex flex-col border-t-0 border sm:border-l-0 sm:border border-solid"
          style="border-color: #e5e7eb"
          v-if="JsonData.card_8"
        >
          <img
            class="w-full hidden md:block"
            :src="JsonData.card_8.desktop_image_url"
            alt="img"
          />
          <img
            class="w-full md:hidden"
            :src="JsonData.card_8.mobile_image_url"
            alt="img"
          />
          <h3 class="pt-4">{{ JsonData.card_8.title }}</h3>
          <div>
            {{ JsonData.card_8.description }}
          </div>
          <div class="w-full h-full flex justify-center items-end mt-8">
            <vs-button @click="redirectToURL(`${JsonData.card_8.link}`)" class="mb-3">
              {{ JsonData.card_8.button_text }}
            </vs-button>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1">
        <!-- Card 6   -->

        <!-- <div
          class="p-6 flex flex-col border-solid border border-t-0"
          style="border-color: #e5e7eb"
        >
          <img
            class="w-full hidden md:block"
            :src="JsonData.card_6.desktop_image_url"
            alt="img"
          />
          <img
            class="w-full md:hidden"
            :src="JsonData.card_6.mobile_image_url"
            alt="img"
          />
          <h3 class="pt-4">{{ JsonData.card_6.title }}</h3>
          <div>
            {{ JsonData.card_6.description }}
          </div>
          <div class="w-full h-full flex items-end mt-8">
            <vs-button @click="redirectToURL(`${JsonData.card_6.link}`)" class="mb-3">
              {{ JsonData.card_6.button_text }}
            </vs-button>
          </div>
        </div> -->
      </div>
    </div>

    <div v-if="productList.length > 0">
      <h3 class="py-5 pl-2">Products</h3>
      <shop-promotions-products :productList="productList" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import shopPromotionsProducts from "../ecommerce/shopPromotionsProducts.vue";
export default {
  components: { shopPromotionsProducts },
  data() {
    return {
      JsonData: {},
      clinicId: "",
      filterParams: {
        categoriesOptions: [],
        brandId: [],
        vendorOptions: [],
        sortBy: "order_asc",
        search: "",
        page: 1,
        limit: 12,
        clinicId: "",
      },
      productList: {},
    };
  },
  computed: {
    breadcrumbItems() {
      return [
        { title: "Home", url: "/"+this.$getUserType()+"/shop" },
        { title: "Promotions", active: true },
      ];
    },
  },
  methods: {
    ...mapActions("eCommerce", ["getJsonSettings", "productFilterListing"]),
    alertMargin() {
      var element = document.getElementsByClassName("router-content");
      if (element) {
        element[0].classList.add("shop-margin");
      }
    },
    async getJsonSettingsData() {
      await this.getJsonSettings({ slug: "promotions" })
        .then((val) => {
          this.JsonData = val.data.data;
          this.filterParams.categoriesOptions = [this.JsonData.products_slug];
          this.filterParams.clinicId = this.clinicId;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async getProductsByCategory() {
      this.$vs.loading();
      try {
        const res = await this.productFilterListing(this.filterParams);
        const categoryExists = res.data.data.categories.length > 0;
        this.productList = categoryExists ? res.data.data.products : [];
        this.$vs.loading.close();
      } catch (error) {
        console.error(error);
        this.$vs.loading.close();
      }
      console.error(error);

      this.$vs.loading.close();
    },

    redirectToURL(url) {
      if (typeof window !== "undefined") {
        window.location.href = this.$replaceUrlTemplate(url, this.$getUserType());
      }
    },
  },
  watch: {
    "$store.state.clinicId": function (val) {
      this.clinicId = val;
      this.filterParams.clinicId = val;
      this.getProductsByCategory();
    },
  },
  async created() {
    this.clinicId = sessionStorage.getItem(`doctorClinicSelectedClinic`);
    await this.getJsonSettingsData();
    this.getProductsByCategory();
  },
};
</script>

<style lang="scss" scoped></style>
